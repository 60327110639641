<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1"
        ><span>{{ $store.state.pageTitle }}</span></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn icon @click="$refs.myRightBar.show()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
    <template>
      <v-container>
        <div class="text-h6 ml-4 mb-2">
          <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
          {{ $store.state.pageTitle }}
        </div>
        <v-divider class="mb-4"></v-divider>
        <p v-if="this.search != ''">{{ subtitle }}</p>
        <v-card elevation="0" class="border-0" tile flat>
          <p
            v-if="showNotFound == true"
            class="text-center py-6 text-warning"
            style="color: #ff6f00"
          >
            <v-icon size="100" color="#FF6F00">mdi-magnify</v-icon> <br />
            <span style="font-size: 1.5em"
              >oopppsss.. <br />buku tidak ketemu..</span
            ><br />
            <br />
            <br />
            <br />
            <v-btn
              color="#ff6f00"
              dark
              class="text-white"
              @click="$refs.myRightBar.show()"
            >
              <v-icon>mdi-magnify</v-icon> Cari lagi disini...
            </v-btn>
          </p>

          <div class="my-2">
            <v-row dense>
              <v-col v-for="(book, i) in books" :key="i" :cols="colSize">
                <BookBox :data="book"></BookBox>
              </v-col>
            </v-row>
          </div>

          <div class="my-2" v-if="loading">
            <v-row dense>
              <v-col :cols="colSize">
                <v-skeleton-loader
                  class="mx-auto"
                  type="card"
                  tile
                ></v-skeleton-loader>
              </v-col>
              <v-col :cols="colSize">
                <v-skeleton-loader
                  class="mx-auto"
                  type="card"
                  tile
                ></v-skeleton-loader>
              </v-col>
              <v-col :cols="colSize">
                <v-skeleton-loader
                  class="mx-auto"
                  type="card"
                  tile
                ></v-skeleton-loader>
              </v-col>
              <v-col :cols="colSize">
                <v-skeleton-loader
                  class="mx-auto"
                  type="card"
                  tile
                ></v-skeleton-loader>
              </v-col>
              <v-col :cols="colSize">
                <v-skeleton-loader
                  class="mx-auto"
                  type="card"
                  tile
                ></v-skeleton-loader>
              </v-col>
              <v-col :cols="colSize">
                <v-skeleton-loader
                  class="mx-auto"
                  type="card"
                  tile
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </div>
          <div class="my-2">
            <v-row dense>
              <v-col class="d-flex justify-center my-4">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loading == true && page > 1"
                ></v-progress-circular>
                <v-btn
                  v-if="found == true && foundBooks == limit"
                  @click="loadMore"
                  >Load more..</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-container>
    </template>

    <RightBar ref="myRightBar" title="Filter">
      <v-text-field
        clearable
        v-model="search"
        placeholder="Cari"
        label="Cari"
      ></v-text-field>
      <v-select
        v-model="filterCategory"
        :items="categories"
        item-value="id"
        item-text="category"
        label="Kategori"
        return-object
      ></v-select>
      <v-text-field
        clearable
        v-model="book_year"
        placeholder="Tahun Buku"
        label="Tahun Buku"
        type="number"
      ></v-text-field>

      <v-row class="mt-2">
        <v-col>
          <v-btn class="mr-1" color="primary" @click="setFilter">Filter</v-btn>
          <v-btn @click="resetFilter">Reset</v-btn>
        </v-col>
      </v-row>
    </RightBar>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import RightBar from "@/layouts/RightBar.vue";
import BookBox from "@/components/BookBox.vue";

export default {
  name: "ElibrarySearch",

  components: {
    DefaultLayout,
    RightBar,
    BookBox,
  },

  data: () => ({
    search: "",
    showNotFound: null,
    loading: null,
    category: null,
    categories: [],
    dialog: false,
    filterCategory: {
      id: null,
      category: "",
    },
    title: null,
    subtitle: null,
    books: [],
    page: 1,
    found: null,
    foundBooks: null,
    limit: 0,
    book_year: null,
  }),

  computed: {
    colSize() {
      // console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 2;
      }
      return 12;
    },
  },

  methods: {
    find() {
      try {
        this.showNotFound = false;
        this.loading = true;

        this.found = false;
        this.foundBooks = 0;

        if (this.page == 1) {
          this.books = [];
        }

        this.search = this.$route.query.search;
        this.category = this.$route.query.category;
        this.book_year = this.$route.query.book_year;

        this.title = "Hasil pencarian:";
        this.subtitle =
          'Menampilkan hasil pencarian dari "' + this.search + '"';

        this.filterCategory = { id: this.category, category: "" };

        var data = {
          params: {
            search: this.search,
            category: this.category,
            book_year: this.book_year,
            page: this.page,
          },
        };
        this.$axios
          .get("elibrary/find", data)
          .then((res) => {
            if (res.data.status == "success") {
              this.limit = res.data.data.limit;
              this.foundBooks = res.data.data.found_book;
              let _this = this;
              if (res.data.data.books.length > 0) {
                res.data.data.books.forEach(function (book) {
                  _this.books.push(book);
                });
                this.found = true;
              } else {
                this.found = false;
              }
            } else {
              if (this.page == 1) {
                this.books = [];
              }
            }
            this.loading = false;

            if (this.page == 1 && this.books.length == 0) {
              this.showNotFound = true;
            } else {
              this.showNotFound = false;
            }
          })
          .catch((error) => {
            this.showAlert("error", error.message);
            this.loading = false;
            this.showNotFound = true;
          });
      } catch (error) {
        this.loading = false;
        this.showNotFound = true;
        this.showAlert("error", error);
      }
    },
    async getCategories() {
      await this.$axios.get("elibrary/categories").then((res) => {
        if (res.data.status == "success") {
          this.categories = [{ id: 0, category: "-- Semua --" }];
          for (var i = 0; i < res.data.data.length; i++) {
            this.categories.push(res.data.data[i]);
          }
        } else {
          this.categories = [];
        }
      });
    },
    loadMore() {
      this.page = this.page + 1;
      this.find();
    },
    setFilter() {
      try {
        if (
          this.$route.query.category != this.search ||
          this.filterCategory.id != this.category ||
          this.$route.query.category != null ||
          this.filterCategory.id != null
        ) {
          this.category = this.filterCategory.id;
          var query = {
            search: this.search,
            category: this.category,
            book_year: this.book_year,
          };
          this.$router
            .replace({ query: query })
            .finally(() => {})
            .catch(() => {});
          this.find();
        }
      } catch (error) {
        console.log(error);
      }

      this.$refs.myRightBar.hide();
    },
    resetFilter() {
      try {
        this.filterCategory = {
          id: null,
          category: "",
        };
        this.search = "";
        this.category = null;
        this.book_year = null;
        this.setFilter();
      } catch (error) {
        console.log(error);
      }
      this.$refs.myRightBar.hide();
    },
  },

  beforeMount() {},

  mounted() {
    this.getCategories();

    this.find();

    this.setPageTitle("E-Perpus - Cari", "mdi-book-open-page-variant");
  },
};
</script>


<style scoped>
.textsmall {
  font-size: 8pt !important;
}
</style>