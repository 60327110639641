<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>{{ book.title }}</v-toolbar-title>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-img
            :src="book.cover_img"
            class="align-end mb-4 mx-auto"
            height="300px"
            v-if="!$store.state.showPageOverlay"
            contain
          >
          </v-img>
        </v-col>
        <v-col cols="12" md="8">
          <div class="text-h5 ml-4 mb-2" style="width: 100%">
            <v-icon color="blue">mdi-book</v-icon> {{ book.title }}
          </div>
          <v-divider></v-divider>

          <v-list dense>
            <v-list-item two-line dense class="min-height:42px">
              <v-list-item-content class="py-0 my-0">
                <v-list-item-title>Kode</v-list-item-title>
                <v-list-item-subtitle>{{ book.code }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line dense class="min-height:42px">
              <v-list-item-content class="py-0 my-0">
                <v-list-item-title>Judul</v-list-item-title>
                <v-list-item-subtitle>{{ book.title }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line dense class="min-height:42px">
              <v-list-item-content class="py-0 my-0">
                <v-list-item-title>Pengarang</v-list-item-title>
                <v-list-item-subtitle>{{ book.author }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line dense class="min-height:42px">
              <v-list-item-content class="py-0 my-0">
                <v-list-item-title>Penerbit</v-list-item-title>
                <v-list-item-subtitle>{{
                  book.publisher
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line dense class="min-height:42px">
              <v-list-item-content class="py-0 my-0">
                <v-list-item-title>Tahun Buku</v-list-item-title>
                <v-list-item-subtitle>{{
                  book.book_year
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <div class="mx-4">
            <v-btn
              link
              target="_blank"
              @click="externalUrl(book.file_url)"
              color="primary"
            >
              Download
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-col>
          <h3>Deskripsi Singkat :</h3>
          <div v-html="book.short_description"></div>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-col>
          <BookByCategory ref="refBookByCategory"></BookByCategory>
        </v-col>
      </v-row>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BookByCategory from "@/components/BookByCategory.vue";

export default {
  name: "ElibraryInfo",
  components: { DefaultLayout, BookByCategory },

  data: () => ({
    book: {},
  }),

  methods: {
    getInfo() {
      this.$store.state.showLoadingDialog = true;
      this.$axios
        .get("elibrary/info", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.$store.state.showLoadingDialog = false;
          if (res.data.status == "success") {
            this.book = res.data.data.book;

            this.$refs.refBookByCategory.getList(
              this.book.elibrary_category_id,
              this.book.id
            );

            this.setPageTitle(
              "E-Perpus | " + this.book.title,
              "mdi-book-open-page-variant"
            );
          } else {
            this.book = [];
          }
        });
    },
  },

  mounted() {
    this.getInfo();
    this.setPageTitle(
      "E-Perpus | " + this.book.title,
      "mdi-book-open-page-variant"
    );
  },
};
</script>