<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 12 : 4"
      class="mx-auto"
      @click="viewInfo(data.id)"
      height="300px"
    >
      <v-img
        :src="data.cover_img"
        class="white--text align-end my-1"
        height="175px"
        contain
      >
      </v-img>
      <v-card-title v-text="data.title" style="font-size: 0.86rem; line-height: 1.5; font-weight: normal"></v-card-title>
      <v-card-subtitle style="font-size: 0.76rem; line-height: 1.5; font-weight: normal">
        {{ data.category_name }} <br />
        Tahun Buku : {{ data.book_year }}  <br />
        <span class="d-inline-block text-truncate" style="max-width: 150px;">{{ data.publisher }}</span>
      </v-card-subtitle>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  methods: {
    viewInfo(id) {
      this.$router.push({
        name:"ElibraryInfo",
        params: {
          id: id
        },
      }).catch(()=>{});
    },
  },
};
</script>