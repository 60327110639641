<template>
  <DefaultLayout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{ $store.state.pageIcon }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$refs.myRightBar.show()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
    <template>
      <v-container>
        <div class="text-h6 ml-4 mb-2">
          <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
          {{ $store.state.pageTitle }}
        </div>
        <v-divider class="mb-4"></v-divider>
        <BookLastUpdate></BookLastUpdate>
      </v-container>
    </template>

    <RightBar ref="myRightBar" title="Filter">
      <v-text-field clearable v-model="search" placeholder="Cari" label="Cari"></v-text-field>
      <v-select v-model="filterCategory" :items="categories" item-value="id" item-text="category" label="Kategori" return-object></v-select>

      <v-row class="mt-2">
        <v-col>
          <v-btn class="mr-1" color="primary" @click="find">Filter</v-btn>
          <v-btn @click="resetFilter">Reset</v-btn>
        </v-col>
      </v-row>
    </RightBar>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import RightBar from "@/layouts/RightBar.vue";
import BookLastUpdate from "@/components/BookLastUpdate.vue"

export default {
  name: "EPerpus",

  components: {
    DefaultLayout,
    RightBar,
    BookLastUpdate,
  },

  data() {
    return {
      searchResult: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      books: [],
      search: [],
      categories: [],
      authors: [],
      publishers: [],
      book_years: [],
      filterCategory: {
        id: null,
        category: '',
      },
      title2: null
    };
  },


  computed: {
    colSize() {
      // console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 2;
        case "xl":
          return 2;
      }
      return 12;
    },
  },


  methods: {
    find() {
      this.searchResult = true

      var searchFilter = {
        search: this.search,
        category: this.filterCategory.id,
      }

      this.$router.push({ name: 'ElibrarySearch', query: searchFilter })

      this.$refs.myRightBar.hide()
    },
    async getCategories(){
      await this.$axios.get("elibrary/categories").then((res) => {
        if (res.data.status == "success") {
          this.categories = res.data.data;
        } else {
          this.categories = [];
        }
      });
    },
    resetFilter() {
      this.filterCategory= {
        id: null,
        category: '',
      }
      this.getList()
    },
  },

  mounted() {
    this.getCategories();
    this.setPageTitle("E-Perpus", "mdi-book-open-page-variant");
  },
};
</script>


<style scoped>
.textsmall {
  font-size: 8pt !important;
}
</style>